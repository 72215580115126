import React, { useState, useRef } from 'react';

interface Message {
  sender: string;
  text: string;
  type:String
  file?: File;
  fileURL?: string;
  imageURL?: string;
  videoURL?: string;
  reaction?: string;
}

import PDFViewer from "./PDFViewer";

/*
const ChatRoom:  React.Component  = (props) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [file, setFile] = useState<File | undefined>(undefined);
  const pdfRef = useRef<HTMLObjectElement>(null); // Référence au PDF

  console.log(props.config)

  

  const handleReact = (messageIndex: number, emoji: string) => {
    const updatedMessages = [...messages];
    updatedMessages[messageIndex].reaction = emoji;
    setMessages(updatedMessages);
  };

  init()

  return (

  );
};

*/



export default class ChatRoom extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
        file:null,
        messages: [],
        title:null
      };
      this.init();
  }
 
  fetchFile = async (urlfile, idelement) => {


    console.log('fetching file:', urlfile);

    if(urlfile.indexOf("blob:http") != -1){
      console.log('fetching BLOB file:'+ urlfile+"  "+idelement);

      document.getElementById(idelement)?.setAttribute("src",urlfile)

    }else{

  
    try {
      const formData = new FormData();
      formData.append('action', "getFile");
    //  formData.append('fileType', "image/png");
      formData.append('filePath', btoa(urlfile));
      formData.append('session', btoa("MY_SESSION_UID"));
  
  //    await fetch('https://virtuor.net/dataAction.php'+"?date="+Date.now(), {
      await fetch('/dataAction.php'+"?date="+Date.now(), {
        method: 'POST',
        cache: 'no-cache',
        headers: {
  //	   'Content-Type': 'multipart/form-data',
        },
        body: formData
  
          //  console.log(arrayBuffer)
       }).then(response => response.blob())
                   .then(blob => {
         // Create an object URL from the Blob
         console.log(blob);

        var objectURL = URL.createObjectURL(blob);
        console.log('fetching file22:', objectURL);

        document.getElementById(idelement)?.setAttribute("src",objectURL)
        //return("objectURL")
                   })

    } catch (error) {
      console.error('Error fetching file:', error);
    }
  }
  };



 fileServerUpload = async(filedata,fileName, pathLocation) => {
    var filetype = filedata["type"];

    const formData = new FormData();
    formData.append('action',"saveFile")
    formData.append('filedata',filedata)
    formData.append('filetype',filetype)
    formData.append('filename',fileName)
    formData.append('pathLocation', pathLocation)

    console.log(this.props.config.parentsceneId);

    console.log(filedata);

 await	fetch('https://elmahdia.com/dataAction.php', {
    method: 'POST',
    headers: {
            //    'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
            //   'Content-Type': 'multipart/form-data',
            //	   'Content-Type': 'application/x-www-form-urlencoded',
            //  'transfer-encoding': 'chunked',
    },
    body: formData
  }).then(response => response.json() )
    .then(async response => {
      console.log(response)
    })
      .catch( (err) => {
    console.log('fetch failed => ', err);
      });

      let date = new Date()
      let dataval = date.getFullYear()+"-"+date.getMonth()+"-"+date.getDay()

      let fileNamePath =  btoa(encodeURIComponent(pathLocation+"/"+fileName))  //  decodeURIComponent(atob(
      let sql_request= "INSERT INTO elmahdiahotspotInfo (title, description,hotspotId, date, sceneId, formattype) VALUES ('"+this.state.title+"', '"+fileNamePath+"'  , '"+this.props.config.id+"' , '"+dataval+"'  , '"+this.props.config.parentsceneId+"' , '"+filetype+"' ) ;" ;
      console.log(sql_request)
       const formData1 = new FormData();
       formData1.append("action", "sql");
       formData1.append("sql_request",   sql_request   );
     
     //    await fetch('https://virtuor.net/dataAction.php'+"?date="+Date.now(), {
       await fetch('/dataAction.php'+"?date="+Date.now(), {
         method: 'POST',
         cache: 'no-cache',
         headers: {
     //	   'Content-Type': 'multipart/form-data',
         },
         body: formData1
     
           //  console.log(arrayBuffer)
        }).then(response => response.json())
                    .then(async dataResult_scene => {
          // Create an object URL from the Blob
          console.log("\n\n ############## ");
         console.log(dataResult_scene);
 
                    })      

}

  uploadFileHotspotInfo__ = (e)=>{
     
    console.log(e.target.files);
    e.preventDefault();
    let sceneId = e.target.getAttribute("name");
    let pathLocation = "content/"+ this.props.config.parentsceneId;
    let reader = new FileReader();
    if (e.target.files && e.target.files[0]) {
         let file = e.target.files[0];
         var fileType = file["type"];
         var fileName = file["name"];

         reader.onloadend = () => {
            this.fileServerUpload(file,fileName, pathLocation);
            console.log(pathLocation+"/"+fileName);
            /*let newLi  = document.createElement("li");
            newLi.innerHTML = pathLocation+"/"+fileName;
            document.getElementById("fileList").appendChild(newLi);*/
          //  document.getElementById('loadFileState').innerHTML =   document.getElementById('loadFileState').innerHTML +pathLocation+"/"+fileName+",";
         }

         reader.readAsDataURL(file)
    }


 }  

uploadFileHotspotInfo = ()=>{
     
     //  console.log(e.target.files);
      // e.preventDefault();
      // let sceneId = e.target.getAttribute("name");
       let pathLocation = "content/"+ this.props.config.parentsceneId;
       let reader = new FileReader();
       let file = this.state.file;
//       if (e.target.files && e.target.files[0]) {
//            let file = e.target.files[0];
       if (file) {

            var fileType = file["type"];
            var fileName = file["name"];

            reader.onloadend = () => {
               this.fileServerUpload(file,fileName, pathLocation);
               console.log(pathLocation+"/"+fileName);
               /*let newLi  = document.createElement("li");
               newLi.innerHTML = pathLocation+"/"+fileName;
               document.getElementById("fileList").appendChild(newLi);*/
             //  document.getElementById('loadFileState').innerHTML =   document.getElementById('loadFileState').innerHTML +pathLocation+"/"+fileName+",";
            }

            reader.readAsDataURL(file)
       }


    }  
  init = async () => {
    console.log("\n\n info Init ############## ");
    console.log(this.props.config);

    const formData = new FormData();
    formData.append("action", "sql");
     formData.append("sql_request", "SELECT DISTINCT * FROM elmahdiahotspotInfo WHERE hotspotId = '"+this.props.config.id+"'   ORDER BY elmahdiahotspotInfo.lastupdate"  );
  
  //    await fetch('https://virtuor.net/dataAction.php'+"?date="+Date.now(), {
    await fetch('/dataAction.php'+"?date="+Date.now(), {
      method: 'POST',
      cache: 'no-cache',
      headers: {
  //	   'Content-Type': 'multipart/form-data',
      },
      body: formData
  
        //  console.log(arrayBuffer)
     }).then(response => response.json())
                 .then(async dataResult => {
       // Create an object URL from the Blob
       console.log("\n\n info ############## ");
      console.log(dataResult);
      dataResult.forEach(element => {

        let newMessage: Message;
        if(element.formattype == "html"){
           newMessage = { sender: 'Other', text: decodeURIComponent(atob(element.description)),fileURL:element.description ,  file: null, type: element.formattype };
        }else{
          if(element.formattype == "pdf"){
            element.formattype="application/pdf"
          }
          let urlfile =  decodeURIComponent(atob(element.description))
          urlfile = urlfile.replace("scenes/","content/")
          console.log(urlfile)
//           newMessage = { sender: 'Other', name: element.title, text: element.title, filePDF: urlfile+'#toolbar=0&navpanes=0&scrollbar=1', file: urlfile+'#toolbar=0&navpanes=0&scrollbar=1', type: 'application/pdf' };
              newMessage = { sender: 'Other', name: element.title, text: element.title, fileURL: urlfile, file: urlfile, type: element.formattype };
        }
        /*
      }else if (element.formattype.startsWith('image/')) {
          const imageURL = URL.createObjectURL(newMessage.file);
          newMessage.imageURL = imageURL;
        } else if (newMessage.file.type.startsWith('video/')) {
          const videoURL = URL.createObjectURL(newMessage.file);
          newMessage.videoURL = videoURL;
        } else if (newMessage.file.type === 'application/pdf') {
          const filePDF = URL.createObjectURL(newMessage.file);
          newMessage.filePDF = filePDF;
        }
      }
      */
    // }    
        console.log(newMessage)
       // this.addMesage(newMessage)
       this.state.messages.push(newMessage);
       this.setState({});

      });
                 });
                 
    };
handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
       // this.setFile(file);
       this.setState({ title: e.target.value });
  
    };

 handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
       // this.setFile(file);
       this.setState({ file: file });
     // this.uploadFileHotspotInfo(e)
      }
    };
  
 addMesage = (content) => {
      const newMessage: Message = { sender: content.sender, text: content.text, file: content.file, type: content.type };

      console.log(newMessage)
      if(newMessage.type == "html"){
        
      }else{
      if (newMessage.file && newMessage.file.type) {
        if (newMessage.file.type.startsWith('image/')) {
          const imageURL = URL.createObjectURL(newMessage.file);
          newMessage.imageURL = imageURL;
          newMessage.fileURL = imageURL;
        } else if (newMessage.file.type.startsWith('video/')) {
          const videoURL = URL.createObjectURL(newMessage.file);
          newMessage.videoURL = videoURL;
          newMessage.fileURL = videoURL;
        } else if (newMessage.file.type === 'application/pdf') {
          const filePDF = URL.createObjectURL(newMessage.file);
          newMessage.filePDF = filePDF;
          newMessage.fileURL = filePDF;

        }
      }
     }

      this.state.messages.push(newMessage);
      //this.setFile(undefined);
      this.setState({file:null});

    }
  
 

    handleMessageSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const inputValue = e.currentTarget.message.value.trim();
     // if (inputValue !== '') {
        const newSender = this.state.messages.length % 2 === 0 ? 'You' : 'Other';
        let content = { sender: newSender, text: inputValue, file: this.state.file, type: this.state.file.type  };
        this.addMesage(content)
        this.uploadFileHotspotInfo()
      //}
    };
  
    handleFullScreen = (e) => {
      if (e.currentTarget) {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        } else {
          let idPDF = e.currentTarget.getAttribute("name")
          console.log(idPDF)
          document.getElementById(idPDF).requestFullscreen();
        }
     }
    };
  
    handleDownload = (fileURL: string) => {
      const a = document.createElement('a');
      a.href = fileURL;
      a.download = 'file.pdf';
      a.click();
    };
  

render() {


  let previousMessages= null;
  if(this.state.messages.length > 0){
    console.log("Message Documents")
    //console.log(this.state.messages[1].file)

   this.state.messages.map((message, messageIndex) => (
    console.log(message)
    ))

    previousMessages = this.state.messages.map((message, messageIndex) => (
    <div key={messageIndex} style={{ display: 'flex', justifyContent: message.sender === 'You' ? 'flex-end' : 'flex-start', marginBottom: '10px' }}>
     <button name={"refPDF"+messageIndex} onClick={this.handleFullScreen} style={{ height:"5vh", backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '5px', padding: '5px 10px', marginTop: '10px', cursor: 'pointer', marginRight: '10px' }}>{document.fullscreenElement ? 'Exit' : 'View'}</button>   
      <div style={{ backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '10px', minWidth: '80%',  maxWidth: '80%',  maxHeight: '30vh', overflow:'scroll' }}>
        
      <strong>{message.sender}:</strong> {message.title}{' '}{message.lastupdate}  
     
        { message.fileURL  && (
          <div>
            {message.type === 'html' ? (
              <div>
                <div id={"refPDF"+messageIndex}  dangerouslySetInnerHTML={{__html: message.text }} style={{ overflow:"scroll" }}></div>
               </div>
            ) : null}
          </div>
        )}
        {message.type.startsWith('application/') ? (
              <div>
                <span> {message.text} </span>
                <PDFViewer id={"refPDF"+messageIndex}  file={message} width="100%" height="300px"/>
              </div>
            ) : (
              <div>
                File: {JSON.stringify(message)}
              </div>
            )}
        {message.type.startsWith('image/') && (
          <div>
            <span> {message.text} </span>            
            <img id={"refPDF"+messageIndex}  src={this.fetchFile(message.fileURL,"refPDF"+messageIndex)} alt="Uploaded" style={{ maxWidth: '100%', marginTop: '10px' }} />
          </div>
        )}
        {message.type.startsWith('video/') && (
          <div>
            <span> {message.text} </span>            
            <video id={"refPDF"+messageIndex}  controls style={{ maxWidth: '100%', marginTop: '10px' }}>
              <source src={this.fetchFile(message.fileURL, "refPDF"+messageIndex)} type={message.file?.type || ''} />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
        <div style={{ display: 'flex', marginTop: '10px', flexWrap: 'wrap' }}>
          {['😀', '❤️', '👍', '😲', '😢'].map((emoji, index) => (
            <button key={index} onClick={() => handleReact(messageIndex, emoji)} style={{ marginRight: '10px', marginBottom: '10px', cursor: 'pointer' }}>
              {emoji}
            </button>
          ))}
        </div>
        {message.reaction && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ marginRight: '10px', marginBottom: '10px' }}>
              {message.reaction}
            </div>
          </div>
        )}
      </div>
    </div>
  ))}

  return (
    <div style={{ overflow:'scroll', maxWidth: '800px', margin: 'auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', maxHeight:'900px', marginTop:'40px' }}>
      <div style={{ marginTop: '20px' }}>
       {previousMessages}
      </div>
      <form onSubmit={this.handleMessageSubmit} style={{ marginTop: '20px', display: 'flex' }}>
        <input
          type="text"
          name="message"
          placeholder="Type your message..."
          onChange={(e) => this.handleTitleChange(e)}
          style={{ flex: '1', padding: '10px', border: '1px solid #ccc', borderRadius: '5px 0 0 5px' }}
        />
        <input
          type="file"
          name="file"
          accept=".png, .jpg, .jpeg, .pdf, .webm"
          onChange={(e) => this.handleFileChange(e)}
        />
        <button type="submit" style={{ backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '0 5px 5px 0', padding: '10px 20px', cursor: 'pointer',marginLeft:'10px' }}>Send</button>
      </form>
    </div>
  )
}

}