import React, { Component } from "react";
import ReactDOM from "react-dom";
import PDF from "react-pdf-js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretLeft,
  faCaretRight,
  faPlusSquare,
  faMinusSquare,
} from "@fortawesome/free-solid-svg-icons";


  export default class PDFViewer extends Component {

  constructor(props) {
   super(props);
    this.state = {
       scale: 1,
       page:1,
       pages: 0,
       filepdf:null
      };
   this.handlePrevious = this.handlePrevious.bind(this);
   this.handleNext = this.handleNext.bind(this);
   this.onDocumentComplete = this.onDocumentComplete.bind(this);
   this.handleScalePlus = this.handleScalePlus.bind(this);
   this.handleScaleMin = this.handleScaleMin.bind(this);
   this.loadFile = this.loadFile.bind(this);


  }

async componentDidUpdate(prevProps) {
    if (prevProps.file !== this.props.file) {
      this.setState({
        scale: 1,
        page:1,
        filepdf:null
      });
      this.loadFile();
    }
  }


async  componentDidMount(){

  this.loadFile();
}

async loadFile(){
  console.log("loadFile")
  console.log(this.props.file)
  if(this.props.file.fileURL.indexOf("blob:https://") != -1){
    this.setState({
      filepdf : this.props.file.fileURL
    });
  }else{
    const formData = new FormData();
    formData.append('action', "getFile");
  //  formData.append('fileType', "image/png");
    formData.append('filePath', btoa(this.props.file.fileURL));
    formData.append('session', btoa("MY_SESSION_UID"));

    await fetch('/dataAction.php'+"?date="+Date.now(), {
      method: 'POST',
      headers: {
  //	   'Content-Type': 'multipart/form-data',
      },
      body: formData
  /*    }).then(response => response.arrayBuffer())
        .then(async arrayBuffer => { */
        //  console.log(arrayBuffer)
     }).then(response => response.blob())
       .then(blob => {
         this.setState({
           filepdf : URL.createObjectURL(blob)
         });
    });
  }


}


  onDocumentComplete(pages) {
    this.setState({ page: 1, pages:pages });

     setTimeout(async () => {
       URL.revokeObjectURL(this.state.filepdf);
    },3000);
  }

  handlePrevious() {
    if(this.state.page > 1){
        this.setState({ page: this.state.page - 1 });
    }
  }

  handleNext() {
      if(this.state.page < this.state.pages){
        this.setState({ page: this.state.page + 1 });
    }
  }

  handleScaleMin() {
      if(this.state.scale > 1){
        this.setState({ scale: this.state.scale - 1 });
    }
  }

  handleScalePlus() {
      if(this.state.scale < 5){
        this.setState({ scale: this.state.scale + 1 });
    }
  }


  render() {


    return (
      <div  id={this.props.id}>


       <button onClick={this.handleScaleMin}><FontAwesomeIcon icon={faMinusSquare}  /></button>{' '}
       <button onClick={this.handlePrevious}><FontAwesomeIcon icon={faCaretLeft}  /></button>{' '}
        {"  "}{"("}{this.state.page}{"/"}{this.state.pages}{")"}{"  "}
       <button onClick={this.handleNext}><FontAwesomeIcon icon={faCaretRight}   /></button>{' '}
       <button onClick={this.handleScalePlus}><FontAwesomeIcon icon={faPlusSquare}   /></button>{' '}

      <div className="App" style={{"maxHeight":"80vh","maxWidth":"100%","overflow":"scroll"}} >

      {this.state.filepdf ?  <PDF
          file={this.state.filepdf}
          page={this.state.page}
          scale={this.state.scale}
          onDocumentComplete={this.onDocumentComplete}
        /> : null }

      </div>
      </div>
    );
  }
}
